.profileContainer {
  display: flex;
  margin-bottom: 18px;
}

.profileContainer figure {
  margin: 0px;
}

.profileContainer figure {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.profileContainer figure img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.formContainer {
  margin-top: 40px;
}

.labelinput {
  flex: 1;
}

.stepstext {
  font-family: 'sorabold';
  color: var(--secondary-color);
  opacity: 0.5;
  text-align: right;
}

.mobContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.mobContainer p {
  margin: 0px;
  color: var(--secondary-color);
  opacity: 0.5;
  margin-right: 4px;
}

.mobContainer::before {
  content: '+91';
  position: absolute;
  color: var(--secondary-color);
  opacity: 0.6;
  font-size: 15px !important;
  left: -4px;
  top: 15px;
}

.mobContainer input {
  padding-left: 30px !important;
}

.mobContainer label {
  padding-left: 28px !important;
}
