.days {
  display: grid;
  margin-top: 0.5rem;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.is-event {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.days-title {
  margin-block: 0.75rem;
  font-size: 0.75rem;
  border-bottom: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  text-align: center;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
}

.day {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
}
.day:hover {
  background-color: #e5e7eb;
}

.months {
  display: grid;
  margin-top: 0.5rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.month {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
}
.month:hover {
  background-color: #e5e7eb;
}

.change-view {
  background-color: transparent;
  color: #111827;
  margin: 0;
  border: none;
  text-align: start;
  padding: 0.375rem;
  flex: 1 1 auto;
  font-weight: 600;
}
.change-view:hover {
  color: #1a1a1a;
}
.change-month {
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0.375rem;
  flex: none;
  justify-content: center;
  align-items: center;
  color: #9ca3af;
}
.change-month:hover {
  color: #1a1a1a;
  font-weight: bold;
}

.py-1_5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.event-container {
  margin-top: 0.25rem;
  margin-inline: auto;
  width: 0.25rem;
  height: 0.25rem;
}

.event {
  border-radius: 9999px;
  width: 0.25rem;
  height: 0.25rem;
  background-color: var(--green-color);
}
