.preview-module-card {
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px;
  width: min(400px, 100%);
  box-shadow: 0px 29px 65px -29px rgb(19 78 82 / 42%);
}

.preview-module-card img {
  object-fit: cover;
  width: 184px;
  height: 184px;
  border-radius: 8px;
}

.preview-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.preview-module-card button {
  pointer-events: none;
  background: var(--primary-gradient);
  border: none;
  width: 100% !important;
  padding: 16px 8px;
}

.preview-category-card {
  user-select: none;
  display: flex;
  align-items: center;
  gap: 8px;
  width: min(400px, 100%);
}

.preview-category-card img {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.preview-category-card h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 800 !important;
  font-family: 'sorabold' !important;
  text-transform: capitalize !important;
}

.edit-category-btn {
  font-size: 18px;
  transition: all 50ms ease-out;
}
.edit-category-btn:hover {
  font-size: 24px;
  color: var(--primary-color);
  cursor: pointer;
}

.preview-flashcard {
  position: relative;
  max-width: 150px;
}

.preview-flashcard img {
  object-fit: cover;
  height: 150px;
  width: 150px;
  border-radius: 10px;
}

.preview-flashcard .delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: white;
  color: rgb(255, 120, 120);
  font-size: 25px;
}

.preview-flashcard .delete-btn:hover {
  cursor: pointer;
  color: red;
  transform: scale(1.1);
}
