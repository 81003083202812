.custom-pagination .page-link {
  background-color: #6bc3ba;
  color: white;
  border-color: #57a69d;
}

.custom-pagination .page-item.active .page-link {
  background-color: #57a69d;
  border-color: #448781;
  color: white;
}

.custom-pagination .page-item:hover .page-link {
  background-color: #57a69d;
  border-color: #448781;
  color: white;
}

.custom-pagination .page-item.disabled .page-link {
  background-color: #c1ebe9;
  color: #8fb5b3;
  border-color: #a7d6d4;
}