:root {
  --primary-color: #80cccf;
  --button-color: #008fda;
  --report-green-color: rgba(75, 170, 80, 1);
  --report-yellow-color: rgba(248, 210, 53, 1);
  --report-red-color: rgba(238, 67, 54, 1);
}

.pdf-page-container p {
  font-size: 11pt;
}

/* .pdf-content-container {
  padding: 0 45px;
} */
.pdf-page-container h1 span {
  font-size: 18px;
  font-weight: 600;
}

.pdf-page-container h2 {
  font-weight: 400;
  font-size: 18pt;
}

.pdf-page-container h3 {
  font-weight: 600;
  font-size: 12pt;
}

.pdf-page-container .main-container {
  margin-bottom: 80px;
}

.pdf-page-container .pdfbtn {
  background-color: var(--button-color);
  padding: 10px 48px;
  border: none;
  color: #fff;
  font-size: 21px;
  border-radius: 8px;
}

.pdf-page-container .pdf-header {
  background-color: var(--primary-color);
  padding: 16px 24px;
  display: flex;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.pdf-page-container .pdf-header h2 {
  color: #fff;
}

/* .pdf-page-container .content-container {
  max-width: 1200px;
  margin: 0 auto;
} */
.pdf-page-container .heading-seprator h1 {
  font-size: 30px;
  color: #000;
  border-bottom: 1px solid var(--button-color);
  position: relative;
  text-transform: uppercase;
}

.pdf-page-container .heading-seprator h1 span {
  position: relative;
  padding-bottom: 10px;
  font-weight: 600;
}

.pdf-page-container .heading-seprator h1 span::after {
  content: '';
  background-color: var(--button-color);
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 2.5px;
  transform: translateY(-50%);
  border-radius: 12ch;
  left: 0;
}

/* contnr tcontaine rcss */
.pdf-page-container .content-container .report-content {
  margin-top: 30px;
}

.pdf-page-container .content-container .pdf-page-container .report-content p {
  margin-bottom: 20px;
}

.pdf-page-container .content-container .listing-container h2 {
  margin-bottom: 20px;
}

.pdf-page-container .content-container .listing-container svg {
  height: 50px;
  width: 50px;
  flex: 0 0 47px;
  margin-right: 16px;
}

.pdf-page-container .content-container .listing-container {
  margin: 28px 0;
}

.pdf-page-container .content-container .listing-container ul {
  padding: 0px;
}

.pdf-page-container .content-container .listing-container li {
  list-style: none;
  margin: 0px;
  display: flex;
  margin-bottom: 20px;
}

.pdf-page-container .content-container .listing-container li p {
  margin: 0px;
  font-family: 'soralight';
  font-size: 9pt;
}

.pdf-page-container .card-container {
  display: flex;
  /* align-items: center; */
  gap: 15px;
}

.pdf-page-container .card-container .card-list {
  background-color: #efefef;
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
  flex: 1 0 calc(33.33% - 22px);
  padding: 20px;
}

.pdf-page-container .card-container .card-list p {
  margin: 0px;
}

.pdf-page-container .card-container .clr-box {
  background-color: red;
  flex: 0 0 42px;
  margin-right: 15px;
  border-radius: 12px;
}

.pdf-page-container .card-container {
  margin-top: 30px;
}

.pdf-page-container .card-container .clr-box.clr-box-green {
  background-color: #00ac57;
}

.pdf-page-container .card-container .clr-box.clr-box-yellow {
  background-color: #ffd034;
}

.pdf-page-container .card-container .clr-box.clr-box-red {
  background-color: #ff2e19;
}

.pdf-page-container .page2-container {
  counter-reset: section;
  padding: 0;
}

.pdf-page-container .page2-container h5 {
  background-color: var(--button-color);
  display: inline-block;
  color: #fff;
  padding: 2px 5px;
  counter-reset: section;
  margin-bottom: 6px;
  border-radius: 20px;
  font-size: 12pt;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding-right: 30px;
  font-weight: 500;
}

.pdf-page-container .page2-container ul li h5 span {
  height: 20px;
  width: 20px;
  background-color: #fff;
  color: var(--button-color);
  border-radius: 50%;
  text-align: center;
  line-height: 1.4;
}

.pdf-page-container .page2-container ul li .list-item {
  margin-bottom: 10px;
}

.pdf-page-container .page2-container .listing-container li {
  display: block;
  margin-bottom: 30px;
}

.pdf-page-container .page8-container .listing-container li {
  display: block;
  flex: 0 0 50%;
}

.pdf-page-container .page8-container .listing-container li:nth-child(1) h5 {
  color: #1851fe;
}

.pdf-page-container .page8-container .listing-container li:nth-child(1) .list-item .high-p {
  color: #1851fe;
}

.pdf-page-container .page8-container .listing-container li:nth-child(2) h5 {
  color: #00bdc3;
}

.pdf-page-container .page8-container .listing-container li:nth-child(2) .list-item .high-p {
  color: #00bdc3;
}

.pdf-page-container .page8-container .listing-container li:nth-child(3) h5 {
  color: #f5457a;
}

.pdf-page-container .page8-container .listing-container li:nth-child(3) .list-item .high-p {
  color: #f5457a;
}

.pdf-page-container .page8-container .listing-container li:nth-child(4) h5 {
  color: #eca549;
}

.pdf-page-container .page8-container .listing-container li:nth-child(4) .list-item .high-p {
  color: #eca549;
}

.pdf-page-container .page8-container .listing-container li:nth-child(5) h5 {
  color: #00a150;
}

.pdf-page-container .page8-container .listing-container li:nth-child(5) .list-item .high-p {
  color: #00a150;
}

.pdf-page-container .page8-container .listing-container li:nth-child(6) h5 {
  color: #00bdc3;
}

.pdf-page-container .page8-container .listing-container li:nth-child(6) .list-item .high-p {
  color: #00bdc3;
}

.pdf-page-container .page8-container .listing-container li:nth-child(7) h5 {
  color: #1851fe;
}

.pdf-page-container .page8-container .listing-container li:nth-child(7) .list-item .high-p {
  color: #1851fe;
}

.pdf-page-container .page8-container .listing-container li h5 {
  font-size: 31pt;
  margin: 0;
}

.pdf-page-container .page8-container .listing-container ul {
  display: flex;
  flex-wrap: wrap;
}

.pdf-page-container .page8-container .listing-container li h3 {
  font-size: 20pt;
  margin-bottom: 4px;
}

.pdf-page-container .page8-container .listing-container li h6 {
  font-size: 11pt;
  font-weight: 600;
}

.pdf-page-container .page8-container .listing-container .list-item {
  padding-right: 24px;
}

.top_bar_box {
  display: flex;
  justify-content: space-between;
}

.progress_cntr {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  gap: 20px;
}

.progress_card_box {
  flex: 1 0 calc(33.33% - 24px);
  background: #efefef;
  padding: 8px;
  border-radius: 15px;
}

.progress_card_box p {
  font-size: xx-small;
}

.botom_row {
  border-top: 2px solid #e1dcdc;
  padding-top: 5px;
}

.progress_detail_box h3 {
  line-height: normal;
}

@media print {
  .progress_detail_box p {
    font-size: 10px;
  }

  .bottom_banner {
    height: 200px !important;
  }

  p {
    line-height: normal;
  }
}

.yellow_text {
  color: #f8d235;
  font-weight: 600;
}

.red_text {
  color: #ee4336;
  font-weight: 600;
}

.green_text {
  color: #4baa50;
  font-weight: 600;
}

.progress_detail_box p {
  color: #868686;
  padding: 8px 0;
}

.brain-img-container svg {
  width: 100% !important;
  height: 100% !important;
}

.pdf-page-container .page8-container .listing-container ul.list_item_cntr {
  gap: 36px;
}

.pdf-page-container .page8-container .listing-container ul.list_item_cntr li {
  flex: 1 0 46%;
}

.pdf-page-container .page8-container .listing-container ul.progress_list {
  gap: 15px;
  text-align: center;
}

.pdf-page-container .page8-container .listing-container ul.progress_list li {
  flex: 1 0 23%;
  background: #efefef;
  padding: 15px;
  border-radius: 15px;
}

.pdf-page-container .page8-container .listing-container ul.progress_list li span {
  font-weight: 600;
}

.bold_text {
  padding-top: 20px;
}

header {
  background-image: url('./headerIimg.svg');
}

.white-logo-svg {
  width: 180px;
}

footer {
  margin-top: 20px;
  border-top: 2px solid #c4c4c4;
  padding: 18px 0;
  text-align: right;
  font-weight: bolder;
}

.brain-img-container svg {
  margin-top: 10px;
}

.cta-container img {
  width: 100%;
}

.cta-container {
  position: relative;
}

.cta-container .report-content {
  position: absolute;
  width: 60%;
  padding: 60px 0;
  left: 7%;
  top: 35%;
}



.cta-container p {
  font-size: 14pt;
}

.cta-container button {
  margin-top: 10px;
  font-size: 12pt !important;
}

@media print {

  .main-container {
    page-break-before: always;
  }

  .main-container ul li {
    page-break-inside: always;
  }

  .cta-container p {
    font-size: 12pt;
  }

  .cta-container .report-content {
    left: 7%;
    top: 10%;
  }


  .pdf-page-container .page8-container .listing-container li h5 {
    font-size: 25pt;
  }

  .pdf-page-container .page8-container .listing-container li h3 {
    font-size: 15pt;
    font-weight: 700;
  }

  .pdf-page-container .page8-container .listing-container li h6 {
    font-size: 8pt;
    font-weight: 600;
  }

  .pdf-page-container .page8-container .listing-container .list-item {
    padding-right: 48px;
  }

  .progress_card_box {
    flex: 1 0 200px;
    background: #efefef;
    padding: 8px;
    border-radius: 15px;
  }

  header {
    top: 0;
  }

  footer {
    bottom: 0;
  }
}

@page {
  size: auto;
  margin: 0mm;
}

.cls-1 {
  fill: #f0f0f0;
}

.cls-10,
.cls-2 {
  fill: #fff;
}

.cls-3 {
  fill: #6d9999;
}

.cls-4 {
  fill: #95cbce;
}

.cls-17,
.cls-19,
.cls-20,
.cls-22,
.cls-5,
.cls-6 {
  font-size: 12px;
}

.cls-16,
.cls-6 {
  fill: #dea74d;
}

.cls-7 {
  fill: none;
  stroke: #d1d1d1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-8 {
  fill: url(#linear-gradient);
}

.cls-17,
.cls-9 {
  fill: #3650ff;
}

.cls-10 {
  font-size: 10px;
}

.cls-11 {
  fill: url(#linear-gradient-2);
}

.cls-12 {
  font-size: 12px;
}

.cls-12,
.cls-20 {
  fill: #40bac0;
}

.cls-13 {
  fill: url(#linear-gradient-3);
}

.cls-14,
.cls-19 {
  fill: #de5180;
}

.cls-15 {
  fill: url(#linear-gradient-4);
}

.cls-18 {
  font-size: 7px;
}

.cls-21 {
  fill: url(#linear-gradient-5);
}

.cls-22 {
  font-family: MyriadPro-Regular, Myriad Pro;
}

.cls-23 {
  letter-spacing: -0.01em;
}

.cls-24 {
  fill: url(#linear-gradient-6);
}

.cls-25 {
  fill: url(#linear-gradient-7);
}

.cls-26 {
  fill: url(#linear-gradient-8);
}

.page1-container {
  /* background-position: 2px -148px;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  width: 100%; */
  /* background-image: url('../images/pdf-bgimg.png'); */

  background-image: url('./img/frontpage.png');
  background-position: 0px 0px;
  background-size: 100%;
  /* height: 100vh; */
  background-repeat: no-repeat;
  width: 100%;
}

.header-content h1 {
  margin: 20px 0px;
  font-family: 'sorabold';
  font-size: 42px;
}

.header-content button {
  margin-top: 20px !important;
}

.header-content {
  padding: 98px 0px;
  width: 312px;
  width: 30%;
}

.icons-container {
  margin-top: 30px;
}

.pdf-content-container h2 {
  margin-top: 20px;
}

.page9-container li:nth-child(1) h3 {
  color: #1851fe;
}

.page9-container li:nth-child(2) h3 {
  color: #00bdc2;
}

.page9-container li:nth-child(3) h3 {
  color: #f44579;
}

.page9-container li:nth-child(4) h3 {
  color: #eba449;
}

.progree_list svg {
  margin-top: 30px;
  height: 120px;
  width: 120px;
}