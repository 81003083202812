.tree-part {
  position: absolute;
}

.fruits {
  top: 7%;
  left: 9%;
}

.fruits::after {
  content: '';
  display: block;
  position: absolute;
  left: 110%;
  top: 50%;
  background-color: rgb(199, 199, 199);
  height: 2px;
  width: 210%;
  z-index: 1;
}

.leaves {
  top: 41.5%;
  left: 0;
}

.leaves::after {
  content: '';
  display: block;
  position: absolute;
  left: 110%;
  top: 50%;
  background-color: rgb(199, 199, 199);
  height: 2px;
  width: 115%;
  z-index: 1;
}

.branch {
  top: 40%;
  right: 0;
}

.branch::before {
  content: '';
  display: block;
  position: absolute;
  right: 105%;
  top: 50%;
  background-color: rgb(199, 199, 199);
  height: 2px;
  width: 165%;
  z-index: 1;
}

.trunk {
  top: 58%;
  right: 17%;
}

.trunk::after {
  content: '';
  display: block;
  position: absolute;
  right: 105%;
  top: 50%;
  background-color: rgb(199, 199, 199);
  height: 2px;
  width: 200%;
  z-index: 1;
}

.roots {
  top: 78%;
  right: 12%;
}

.roots::after {
  content: '';
  display: block;
  position: absolute;
  right: 105%;
  top: 50%;
  background-color: rgb(199, 199, 199);
  height: 2px;
  width: 170%;
  z-index: 1;
}
