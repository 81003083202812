.imageContainer {
  text-align: right;
}

.imageContainer figure {
  margin: 0px;
}

.btnContainer {
  text-align: right;
}

.btnContainer button {
  border: none;
  background-color: transparent;
  font-size: 10px;
  margin-left: 4px;
}

.btnContainer button i {
  margin-right: 2px;
}

.btnContainer button:nth-child(2) {
  color: #6bc3ba;
}

.btnContainer p {
  margin: 0px;
  font-size: 12px;
  color: var(--secondary-color);
  opacity: 0.5;
  text-align: center;
  margin-top: 8px;
}

.btnContainer .redirectoption {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.redirectoption button {
  margin-right: 15px;
  margin-top: 0px;
}

.redirectoption p {
  margin: 0px;
  color: var(--primary-color);
}

.addButton {
  border: 2px solid var(--primary-color);
  width: 100px;
  text-align: center;
  color: var(--primary-color);
  background-color: #fff;
  padding: 12px;
  font-size: 50px;
  font-family: 'sorabold';
  border-radius: 25px;
  cursor: pointer;
  margin-top: 14px;
}

.cardContainer {
  cursor: pointer;
  box-shadow: 0px 5px 37px -15px rgb(19 78 82 / 13%);
  padding: 12px 14px;
  margin-bottom: 12px;
  border: 2px solid #fff;
}

.cardContainer.active {
  border: 1px solid var(--primary-color);
}

.cardContainer h4 {
  font-size: 16px;
  font-family: 'sorabold';
}

.cardContainer img {
  height: 80px;
  width: 80px;
}

.cardContainer p {
  margin: 0px;
  color: var(--secondary-color);

  font-size: 12px;
}

.cardContainer span {
  margin: 0px;
  font-size: 12px;
  color: var(--secondary-color);
  opacity: 0.5;
}

.addBtn {
  border: 2px solid var(--primary-color);
  padding: 4px 10px;
  border-radius: 5px;
  color: var(--primary-color);
  font-size: 14px;
  width: auto !important;
  background: #fff;
  display: block;
  margin-left: auto;
  margin-top: 20px !important;
}

.addBtn:hover {
  background: var(--primary-gradient);
  color: #fff;
}