.Container {
  display: grid;
  gap: 6px;
  grid-template-columns: repeat(5, auto);
}

.FillSquare {
  display: flex;
  background: #ba8f00;
  color: white;
  cursor: pointer;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  width: 75px;
  height: 75px;
  border: 4px solid #000;
  border-radius: 8px;
}

.CorrectSquare {
  display: flex;
  background: #f4c531;
  color: white;
  cursor: pointer;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  width: 75px;
  height: 75px;
  border: 4px solid #000;
  border-radius: 8px;
}

.EmptySquare {
  display: flex;
  background: #ffffff00;
  color: white;
  cursor: pointer;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  width: 75px;
  height: 75px;
  border: 4px solid #000;
  border-radius: 8px;
}
